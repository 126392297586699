import { Component, Input } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxRate } from "@expertasolutions/budgetplanner-ui-common";

@Component({
    selector: 'delete-taxrate',
    templateUrl: './deleteTaxRateModal.component.html',
})
export class DeleteTaxRateModalComponent {
  @Input() currentEntity?: TaxRate
  @Input() modal:any

  public errors = [];

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  public deleteEntity() {
    this.bpService.deleteEntity('/api/taxrate/delete', this, () => {
      this.modal.close();
    });
  }
}
