import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient} from "@angular/common/http";
import { CommonBPConfig} from "./globalBpConfig";
import { WizardBudgetPlannerService} from "./WizardBudgetPlannerService";
import { BudgetPlannerServiceBase} from "@expertasolutions/budgetplanner-ui-common";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {provideOAuthClient} from "angular-oauth2-oidc";
import {provideAnimations} from "@angular/platform-browser/animations";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideOAuthClient(),
    CommonBPConfig,
    WizardBudgetPlannerService,
    {
      provide: BudgetPlannerServiceBase, useClass: WizardBudgetPlannerService
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }
    },
  ],
};
