import {Routes} from "@angular/router";
import {RegisterComponent} from "./theme/layout/Register/register.component";
import {PlanSelectorComponent} from "./theme/layout/PlanSelector/planSelector.component";

export const routes: Routes = [
    {
        path: 'r', component: RegisterComponent
    },
    {
        path: 'r', component: RegisterComponent,
        // children: [
        //     { path: '', loadChildren: () => import('./theme/layout/Register/register.module').then(m=> m.RegisterModule) }
        // ]
    },
    {
        path: 'p', component: PlanSelectorComponent,
        // children: [
        //     { path: '', loadChildren: () => import('./theme/layout/PlanSelector/planSelector.module').then(m=> m.PlanSelectorPageModule) }
        // ]
    }
];
