import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {BudgetplannerUiCommonModule, CompanyInfo, Country, State} from "@expertasolutions/budgetplanner-ui-common";
import { WizardDataSingleton } from "../../../WizardDataSingleton";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
@Component({
  selector: 'companyInfoWidget',
  templateUrl: './companyInfoWidget.component.html',
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    BudgetplannerUiCommonModule
  ]
})
export class CompanyInfoWidgetComponent implements OnInit {
  @Input() currentEntity: CompanyInfo;
  @Output() abortEvent:EventEmitter<any> = new EventEmitter<any>();
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();
  public errors = [];
  public cmnSingleton: WizardDataSingleton = null;

  public disableCustomFiscalPeriod = false;

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  public abort() {
    this.abortEvent.emit();
  }

  public submit() {
    this.submitEvent.emit();
  }

  ngOnInit() {
    this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);
  }

  public get isNew() {
    return this.currentEntity.identifier === null;
  }

  public onOrgTypeChange() {
    let currentOrg = this.cmnSingleton.organizationTypes.filter(x=> x.identifier == this.currentEntity.orgTypeIdentifier)[0];
    if(currentOrg === null || currentOrg === undefined) {
      return;
    }

    let countryOrgType = currentOrg.organizationTypeConfigs?.filter(x=> x.organizationTypeIdentifier == this.currentEntity.orgTypeIdentifier)[0];
    if(countryOrgType === null || countryOrgType === undefined) {
      this.currentEntity.fyStartMonth = null;
      this.currentEntity.fyStartDay = null;
    } else if(countryOrgType.fyStartDay !== null) {
      this.currentEntity.fyStartMonth = countryOrgType.fyStartMonth;
      this.currentEntity.fyStartDay = countryOrgType.fyStartDay;
      this.disableCustomFiscalPeriod = !countryOrgType.fyCustomAllowed;
      return;
    }
    this.disableCustomFiscalPeriod = false;
  }

  public onCountryChange() {
    let currentOrgType = this.cmnSingleton.organizationTypes.filter(x=> x.identifier == this.currentEntity.orgTypeIdentifier)[0];
    if(currentOrgType === null || currentOrgType === undefined) {
      return;
    }

    let curOrgTypeConfig = currentOrgType.organizationTypeConfigs?.filter(
      x=> x.organizationTypeIdentifier == this.currentEntity.orgTypeIdentifier && x.countryIdentifier == this.currentEntity.countryIdentifier)[0];
    if(curOrgTypeConfig === null || curOrgTypeConfig === undefined) {
      this.currentEntity.fyStartMonth = null;
      this.currentEntity.fyStartDay = null;
    } else if (curOrgTypeConfig.fyStartDay !== null) {
      this.currentEntity.fyStartMonth = curOrgTypeConfig.fyStartMonth;
      this.currentEntity.fyStartDay = curOrgTypeConfig.fyStartDay;
      this.disableCustomFiscalPeriod = !curOrgTypeConfig.fyCustomAllowed;
      return;
    }
    this.disableCustomFiscalPeriod = false;
  }

  public onTimezoneChanged() {
    //console.log(this.currentEntity.timezone);
  }

  public get getDaysNumberFromMonth() {
    let currentMonth = this.cmnSingleton.monthsList.filter(x=> x.id == this.currentEntity.fyStartMonth)[0];
    if(currentMonth === null || currentMonth === undefined) {
      return 0;
    }
    return Array(currentMonth.maxdays).fill(0).map((x, i) => i + 1);
  }

  public get displaybaleCountries() {
    let currentOrgType = this.cmnSingleton.organizationTypes.filter(x=> x.identifier == this.currentEntity.orgTypeIdentifier)[0];
    if(currentOrgType === null || currentOrgType === undefined) {
      return [] as Country[];
    }

    let result = currentOrgType.organizationTypeConfigs?.filter(
      x=> x.organizationTypeIdentifier == this.currentEntity.orgTypeIdentifier);
    if(result === null || result === undefined) {
      return [] as Country[];
    }
    return result.map(x=> x.country) as Country[];
  }

  public get displayableStates() {
    let currentOrgType = this.cmnSingleton.organizationTypes.filter(x=> x.identifier == this.currentEntity.orgTypeIdentifier)[0];
    if(currentOrgType === null || currentOrgType === undefined) {
      return [] as State[];
    }

    let curOrgTypeConfig = currentOrgType.organizationTypeConfigs?.filter(
      x=> x.organizationTypeIdentifier == this.currentEntity.orgTypeIdentifier && x.countryIdentifier == this.currentEntity.countryIdentifier)[0];
    if(curOrgTypeConfig === null || curOrgTypeConfig === undefined) {
      return [] as State[];
    }
    return curOrgTypeConfig.country.states as State[];
  }
}
