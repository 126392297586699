import { Component, Input, OnInit } from '@angular/core';
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";

import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxType, TaxRate } from "@expertasolutions/budgetplanner-ui-common";
import {FormsModule} from "@angular/forms";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {CreateUpdateTaxRateComponent} from "./createUpdateTaxRateModal.component";
import {DeleteTaxRateModalComponent} from "./deleteTaxRateModal.component";
import {actionButton} from "../../shared/components/bp-widgets/actionButton.component";

@Component({
  selector: 'createUpdate-taxtype',
  templateUrl: './createUpdateTaxTypeModal.component.html',
  imports: [
    FormsModule,
    DatePipe,
    CreateUpdateTaxRateComponent,
    DeleteTaxRateModalComponent,
    actionButton,
    NgForOf,
    NgIf
  ]
})
export class CreateUpdateTaxTypeComponent implements OnInit {
  @Input() currentEntity?:TaxType
  @Input() modal:any

  public errors = [];
  public currentRate: TaxRate = null;

  constructor(private bpService:WizardBudgetPlannerService, private modalService: NgbModal) {

  }

  ngOnInit() {

  }

  public reloadData() {
    this.bpService.reloadData("taxtype", this.currentEntity.identifier)
      .then(result => {
        this.currentEntity = result.data;
      });
  }

  public viewEdit(model:TaxRate, content:any) {
    if(model == null) {
      this.currentRate = {} as TaxRate;
      this.currentRate.taxTypeIdentifier = this.currentEntity.identifier;
      this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxRate'}).result.then((result) => {
        console.log(result);
        this.reloadData();
      });
    } else {
      this.bpService.reloadData("taxrate", model.identifier)
        .then(result => {
          this.currentRate = result.data;
          this.modalService.open(content, {ariaLabelledBy: 'modal-createUpdateTaxRate'}).result
            .then(() => {
              this.reloadData();
            });
        })
    }
  }

  public viewDelete(model:TaxRate, content:any) {
    this.currentRate = model;
    this.modalService.open(content, {ariaLabelledBy: 'modal-deleteTaxRate'}).result.then((result) => {
      this.reloadData();
    });
  }

  public get isNew() {
    return this.currentEntity.identifier == null;
  }

  public save() {
    this.bpService.submitContent("/api/taxType/", this, ()=> {
      this.modal.close();
    });
  }
}
