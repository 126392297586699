import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import {Plan, PlanFeature} from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'planWizard',
  styleUrls: ['./planWizard.component.scss'],
  templateUrl: './planWizard.component.html',
  imports: [
    NgClass,
    NgForOf,
    NgIf
  ]
})
export class PlanWizardComponent implements OnInit {
  @Output() selectedPlanIdentifierEvent: EventEmitter<string> = new EventEmitter<string>();
  public sglCommonData: WizardDataSingleton = null;
  public selectedPlanIdentifier: string;

  constructor(public bpService:WizardBudgetPlannerService) { }

  async ngOnInit() {
    await this.bpService.init();
    this.sglCommonData = WizardDataSingleton.getInstance(this.bpService);
  }

  public selectPlan(planIdentifier: string) {
    this.selectedPlanIdentifier = planIdentifier;
    this.selectedPlanIdentifierEvent.emit(this.selectedPlanIdentifier);
  }

  public orderPlan(a: Plan, b: Plan) : number {
    return a.order - b.order;
  }

  public activePlan(a: Plan): boolean {
    return a.available && a.visible;
  }

  public orderFeature(a: PlanFeature, b: PlanFeature) : number {
    return a.feature.order - b.feature.order;
  }

  public activeFeature(a: PlanFeature) : boolean {
    return a.feature.visible;
  }
}
