import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import { Employee } from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";
import {CreateUpdateEmployeeComponent} from "./createUpdateEmployeeModal.component";
import {DeleteEmployeeComponent} from "./deleteEmployeeModal.component";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'employeesWizard',
  templateUrl: './employeesWizard.component.html',
  imports: [
    CreateUpdateEmployeeComponent,
    DeleteEmployeeComponent,
    NgForOf
  ]
})
export class EmployeesWizardComponent implements OnInit {
  public employeesList = [];
  public currentEntity: Employee = null;
  public sglCommonData: WizardDataSingleton = null;

  public tableOptions: any = {};
  public myTable : any = null;

  private errors: any = [];

  constructor(private bpService:WizardBudgetPlannerService, private modalService: NgbModal) {

  }

  ngOnInit() {
    this.sglCommonData = WizardDataSingleton.getInstance(this.bpService);

    this.tableOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      searching: false,
      ordering: true,
      processing: false,
      paging: false,
      retrieve: true,
      scrollX: false,
      scrollY: 316,
      info: false,
      order: [[ 1, "asc"]],
      columnDefs: [
        { targets: 0, orderable: false}
      ]
    };

    this.reloadData();
  }

  public initLoad() {
    if(this.myTable !== null) {
      this.myTable.destroy();
    }
    setTimeout(() => {
      const myTable: any = document.querySelector('#myTable');
      this.myTable = myTable.DataTable(this.tableOptions);
    }, 1);
  }

  public reloadData() {
    this.sglCommonData.reloadEmployees(() => this.initLoad());
  }

  public viewEdit(model:Employee, content:any) {
    this.errors = [];
    this.currentEntity = Object.assign({}, model);

    this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size:'xl',
        centered: true
      }
    ).result.then(() => {
      this.reloadData();
    });
  }

  public viewDelete(model:Employee, content:any) {
    this.errors = [];
    this.currentEntity = model;
    this.modalService.open(content, {ariaLabelledBy: 'modal-delete-employee'}).result.then(() => {
      this.reloadData();
    });
  }
}
