import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'action-button',
  templateUrl: './actionButton.component.html',
  imports: [
    NgClass,
    NgIf
  ]
})
export class actionButton implements OnInit {
  @Input() model?:any;
  @Output() viewDocument:EventEmitter<any> = new EventEmitter<any>();
  @Output() viewEdit:EventEmitter<any> = new EventEmitter<any>();
  @Output() viewDelete:EventEmitter<any> = new EventEmitter<any>();
  @Input() hasDocument:Boolean = false;
  @Input() hasEdit: boolean = true;
  @Input() hasDelete: boolean = true;
  @Input() parentIsValidated?: boolean;

  constructor() {}
  ngOnInit() {
  }

  public showDocument() {
    this.viewDocument.emit();
  }

  public edit() {
    if(!this.model.validated && !this.parentIsValidated) {
      this.viewEdit.emit();
    }
    else if(this.parentIsValidated == null) {
      this.viewEdit.emit();
    }
  }

  public delete() {
    if(!this.model.validated && !this.parentIsValidated) {
      this.viewDelete.emit();
    }
  }
}
