import { Component, Input, OnInit } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import { Employee } from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";
import {NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavLinkBase, NgbNavOutlet} from "@ng-bootstrap/ng-bootstrap";
import {CurrencyPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CardComponent} from "../../shared/components/card/card.component";

@Component({
  selector: 'createUpdate-employee',
  templateUrl: './createUpdateEmployeeModal.component.html',
  imports: [
    NgbNavItem,
    NgbNav,
    CurrencyPipe,
    FormsModule,
    NgbNavOutlet,
    CardComponent,
    NgIf,
    NgbNavLink,
    NgbNavContent,
    NgbNavLinkBase,
    NgForOf,
    DatePipe
  ]
})
export class CreateUpdateEmployeeComponent implements OnInit {
  @Input() currentEntity?:Employee
  @Input() modal:any
  @Input() showStats: boolean = true

  public errors = [];
  public activeTab = 1;
  public cmnSingleton: WizardDataSingleton = null;

  public kpiStats: any = {
    hoursWorkThisMonth : 0,
    hoursWorkLastMonth : 0,
    salaryThisMonth : 0.00,
    salaryLastMonth : 0.00,
    salaryThisYear: 0.00,
    salaryLastYear: 0.00
  };

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  ngOnInit() {
    this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);

    this.bpService.loadInto(`/api/employee/kpistats/${this.currentEntity.identifier}`)
      .then(result=> {
        console.log(result);
        this.kpiStats = result;
      });
  }

  public get isNew() {
    return this.currentEntity.identifier === null;
  }

  public save() {
    this.bpService.submitContent("/api/employee/", this, ()=> {
      this.modal.close();
    });
  }
}
