import { Component, Input, OnInit } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import { TaxSystem } from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";
import {FormsModule} from "@angular/forms";
import {DatePipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'createUpdate-taxsystem',
  templateUrl: './createUpdateTaxSystemModal.component.html',
  imports: [
    FormsModule,
    DatePipe,
    NgForOf,
    NgIf
  ]
})
export class CreateUpdateTaxSystemComponent implements OnInit {
  @Input() currentEntity?:TaxSystem
  @Input() modal:any

  public errors = [];
  public cmnSingleton: WizardDataSingleton = null;

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  ngOnInit() {
    this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);
  }

  public get isNew() {
    return this.currentEntity.identifier == null;
  }

  public save() {
    this.bpService.submitContent("/api/taxsystem/", this, ()=> {
      this.modal.close();
    });
  }
}
