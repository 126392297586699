import { Component, Input, Output } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { Employee } from "@expertasolutions/budgetplanner-ui-common";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'delete-employee',
  templateUrl: './deleteEmployeeModal.component.html',
  imports: [
    NgForOf
  ]
})
export class DeleteEmployeeComponent {
  @Input() currentEntity?:Employee
  @Input() modal:any

  public errors = [];

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  public deleteEntity() {
    this.bpService.deleteEntity('/api/employee/delete', this, () => {
      this.modal.close();
    });
  }
}
