import { Component, Input } from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import { TaxType } from "@expertasolutions/budgetplanner-ui-common";

@Component({
    selector: 'delete-taxtype',
    templateUrl: './deleteTaxTypeModal.component.html',
})
export class DeleteTaxTypeModalComponent {
  @Input() currentEntity?: TaxType
  @Input() modal:any

  public errors = [];

  constructor(private bpService:WizardBudgetPlannerService) {

  }

  public deleteEntity() {
    this.bpService.deleteEntity('/api/taxtype/delete', this, () => {
      this.modal.close();
    });
  }
}
