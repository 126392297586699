import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {SpinnerComponent} from "./theme/shared/components/spinner/spinner.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
      RouterOutlet,
      SpinnerComponent
    ]
})
export class AppComponent implements OnInit {
  title = 'datta-able';

  constructor(private router: Router) {

  }

  ngOnInit() {
    console.log('appComponent');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

    });
  }
}
